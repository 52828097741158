import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  desktopBreakpoint,
  colors,
  desktopVW,
  mobileVW,
  zIndex,
  mobileContentSidePadding,
} from '../styles/index';
import { graphql } from 'gatsby';
import ReactMapGL, { Marker, FlyToInterpolator } from 'react-map-gl';
import Icons from '../utils/Icons';
import Img from 'gatsby-image';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useSpring, animated, config } from 'react-spring';
import { useIsMobile, useWindowSize } from '../hooks/index';

export interface findUsProps {
  data: {
    contentfulPageFindUs: {
      storeLocatorText: string;
      premiumDealersTitle: string;
      premiumDealersMarkerColor: string;
      standardDealersTitle: string;
      standardDealersMarkerColor: string;
      salesPointTitle: string;
      salesPointMarkerColor: string;
      stores: [
        {
          title: string;
          storeType: string;
          addressFirstLine: string;
          addressSecondLine: string;
          country: string;
          website: string;
          location: {
            lat: number;
            lon: number;
          };
          thumbnailImage: {
            fluid: string;
          };
        }
      ];
    };
  };
}

const FindUs = ({ data: { contentfulPageFindUs } }: findUsProps) => {
  const {
    stores: allStores,
    storeLocatorText,
    premiumDealersTitle,
    premiumDealersMarkerColor,
    standardDealersTitle,
    standardDealersMarkerColor,
    salesPointTitle,
    salesPointMarkerColor
  } = contentfulPageFindUs;
  const isMobile = useIsMobile();
  const [, setY] = useSpring(() => ({ y: 0 }));
  const [mapData, setMapData] = useState({});
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedStore, setSelectedStore] = useState(false);

  const [firstDropdownOpen, setFirstDropdownOpen] = useState(false);
  const [secondDropdownOpen, setSecondDropdownOpen] = useState(false);
  const [thirdDropdownOpen, setThirdDropdownOpen] = useState(false);

  useEffect(() => {
    console.log(firstDropdownOpen);
  }, [firstDropdownOpen])

  
  const initialViewport = {
    latitude: isMobile ? 52.1 : 52.3,
    longitude: isMobile ? 5.3 : 4.804431,
    zoom: isMobile ? 5.7 : 7,
  };

  const viewportLocation = {
    "netherlands": {
      latitude: isMobile ? 52.1 : 52.3,
      longitude: isMobile ? 5.3 : 4.804431,
      zoom: isMobile ? 5.7 : 7,
    },
    "nederland": {
      latitude: isMobile ? 52.1 : 52.3,
      longitude: isMobile ? 5.3 : 4.804431,
      zoom: isMobile ? 5.7 : 7,
    },
    "germany": {
      latitude: isMobile ? 51.5 : 52,
      longitude: isMobile ? 10 : 6,
      zoom: isMobile ? 4 : 5.5
    },
    "duitsland": {
      latitude: isMobile ? 51.5 : 52,
      longitude: isMobile ? 10 : 6,
      zoom: isMobile ? 4 : 5.5
    }
  }

  const relocateViewportOnCountry = (country) => {
    country = country.toLowerCase();
    try {
    setViewport({
      longitude: viewportLocation[country].longitude,
      latitude: viewportLocation[country].latitude,
      zoom: viewportLocation[country].zoom,
      transitionInterpolator: new FlyToInterpolator({
        speed: 1.5,
      }),
      transitionDuration: 'auto',
    })
    } catch(e) {

    }
  }

  const useViewport = initialViewport => {
    const [viewport, setViewport] = useState(initialViewport);
    const { width, height } = useWindowSize();

    return [
      { ...viewport, width: width || '100%', height: isMobile ? '100%' : height || '100%' },
      setViewport,
    ];
  };
  const [viewport, setViewport] = useViewport(initialViewport);

  const goToViewport = ({ longitude, latitude }) => {
    setViewport({
      longitude,
      latitude,
      zoom: 15,
      transitionInterpolator: new FlyToInterpolator({
        speed: 1.5,
      }),
      transitionDuration: 'auto',
    });
  };

  const handleThumbnailClick = (geometry, properties) => {
    setSelectedStore(properties);
    geometry.latitude += 0.0007; //make some space for the infobox
    if (isMobile) {
      goToViewport(geometry);
      setTimeout(() => {
        setY({
          y: 0,
          reset: true,
          from: { y: window.scrollY },
          onFrame: props => window.scroll(0, props.y),
        });
      }, 150);
    } else {
      goToViewport(geometry);
    }
  };

  const handleThumbnailHover = properties => {
    setSelectedStore(properties);
  };

  const handlecloseInfo = () => {
    setSelectedStore(false);
  };

  const allStoreCountries = [...new Set(allStores.map(item => item.country))];

  const mapDataStructure = store => ({
    type: 'Feature',
    properties: {
      title: store.title,
      addressFirstLine: store.addressFirstLine,
      addressSecondLine: store.addressSecondLine,
      country: store.country,
      website: store.website,
      thumbnail: store.thumbnailImage,
      type: store.storeType,
    },
    geometry: {
      coordinates: [store.location.lat, store.location.lon],
      latitude: store.location.lat,
      longitude: store.location.lon,
      type: 'Point',
    },
  });

  useEffect(() => {
    //  *** creating map data structure from contentful Stores field
    if (Object.keys(mapData)?.length < 1) {
      //  *** getting array with unique countries
      setSelectedCountry(allStoreCountries[0].toLowerCase()); // Default to first one (but due to locale differences "netherlands" v "nederlands", we're doing it here instead of in the setState)
      const tempMapData = {};
      //  *** iterate & push the object to tempMapData
      allStoreCountries.forEach(country => {
        const storesInThisCountry = allStores.filter(
          store => store.country.toLowerCase() === country.toLowerCase()
        );
        tempMapData[country.toLowerCase()] = storesInThisCountry.map(store =>
          mapDataStructure(store)
        );
      });
      //  *** set the state after all iterations are done
      setMapData(tempMapData);
    } else return;
  }, []);

  const premiumStores = mapData[selectedCountry]?.filter(
    item => item.properties.type === 'premium'
  );
  const standardStores = mapData[selectedCountry]?.filter(
    item => item.properties.type === 'standard'
  );
  const salesPoints = mapData[selectedCountry]?.filter(
    item => item.properties.type === 'sales point'
  );

  const shopJsxStructure = (properties, geometry, index, storeType) => (
    <StoreThumbnail
      key={properties.title}
      onClick={() => handleThumbnailClick(geometry, properties)}
      isFirst={
        index === 0
      }
      isLast={
        index ===
        mapData[selectedCountry].filter(item => item.properties.type === storeType)?.length - 1
      }
    >
      <StyledImg fluid={properties.thumbnail.fluid} />
      <StoreInfo>
        <p className="storeInfoTitle">{properties.title}</p>
        <div className="storeInfoParagraph">
          <p>{properties.addressFirstLine}</p>
          <p>{properties.addressSecondLine}</p>
          <p>{properties.country}</p>
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://${properties.website}`}
          className="storeInfoParagraph"
        >
          {properties.website}
        </a>
      </StoreInfo>
    </StoreThumbnail>
  );

  return (
    <Wrapper>
      <MapWrapper>
        <ReactMapGL
          {...viewport}
          reuseMaps
          mapStyle="mapbox://styles/morriskotkamp/ckir7c2vz07c517qm3zqq26j0"
          mapboxApiAccessToken={process.env.MAPBOX_ACCESS_TOKEN}
          onViewportChange={setViewport}
        >
          {mapData[selectedCountry] &&
            mapData[selectedCountry].map(({ properties, geometry }) => (
              <Marker
                key={properties.title}
                latitude={geometry.coordinates[0]}
                longitude={geometry.coordinates[1]}
              >
                <MapMarker>
                  <InfoBox
                    isActive={selectedStore ? selectedStore.title === properties.title : false}
                  >
                    <p className="storeInfoTitle">{properties.title}</p>
                    <div className="storeInfoParagraph">
                      <p>{properties.addressFirstLine}</p>
                      <p>{properties.addressSecondLine}</p>
                      <p>{properties.country}</p>
                    </div>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`https://${properties.website}`}
                      className="storeInfoParagraph"
                    >
                      {properties.website}
                    </a>
                    <button
                      className="thumbnailCloseInfo"
                      type="button"
                      aria-label="close info"
                      onClick={() => handlecloseInfo()}
                    >
                      X
                    </button>
                  </InfoBox>
                  <div
                    onMouseEnter={() => handleThumbnailHover(properties)}
                    onClick={() => handleThumbnailHover(properties)}
                  >
                    <Icons
                      name="mapMarker"
                      color={
                        properties.type === "premium" ? 
                        premiumDealersMarkerColor :
                        properties.type === "standard" ?
                        standardDealersMarkerColor :
                        salesPointMarkerColor
                    }
                    />
                  </div>
                </MapMarker>
              </Marker>
            ))}
        </ReactMapGL>
      </MapWrapper>
      <MapSidebar>
        <MapSidebarHeader>
          <MapCountrySelectTitle>{storeLocatorText}</MapCountrySelectTitle>
          <MapCountrySelectWrapper>
            <MapCountrySelectArrow/>
            <MapCountrySelect
              value={selectedCountry}
              onChange={e => {
                setSelectedCountry(e.target.value.toLowerCase());
                relocateViewportOnCountry(e.target.value.toLowerCase()); // Default to first one (but due to locale differences "netherlands" v "nederlands", we're doing it here instead of in the setState)
              }}
            >
              {allStoreCountries.map(country => (
                <option value={country.toLowerCase()} key={country}>
                  {country}
                </option>
              ))}
            </MapCountrySelect>
        </MapCountrySelectWrapper>
        </MapSidebarHeader>
        <OverflowScroll>
          <MapSidebarBody>
            {premiumStores?.length > 0 && (
              <>
              <MapSidebarDivider />
              <MapSidebarDropdown open={firstDropdownOpen} >
                <MapSidebarDropdownHeader onClick={() => setFirstDropdownOpen(!firstDropdownOpen)}>
                  {premiumDealersTitle}
                </MapSidebarDropdownHeader>
                <MapSidebarDropdownButton>
                  {firstDropdownOpen ? "-" : "+"}
                </MapSidebarDropdownButton>
                {/* <MapSidebarTitle>{premiumDealersTitle}</MapSidebarTitle> */}
                {premiumStores.map(({ properties, geometry }, index) =>
                  shopJsxStructure(properties, geometry, index, 'premium')
                )}
              </MapSidebarDropdown>
              {console.log(standardStores, salesPoints)}
                {standardStores?.length <= 0 && salesPoints?.length <= 0 ? (
                  <MapSidebarDivider />
                ) : (
                  <></>
                )}
              </>
            )}
            {standardStores?.length > 0 && (
              <>
              <MapSidebarDivider />
              <MapSidebarDropdown open={secondDropdownOpen}>
                <MapSidebarDropdownHeader onClick={() => setSecondDropdownOpen(!secondDropdownOpen)}>
                  {standardDealersTitle}
                </MapSidebarDropdownHeader>
                <MapSidebarDropdownButton>
                  {secondDropdownOpen ? "-" : "+"}
                </MapSidebarDropdownButton>
                {/* <MapSidebarTitle>{standardDealersTitle}</MapSidebarTitle> */}
                {standardStores.map(({ properties, geometry }, index) =>
                  shopJsxStructure(properties, geometry, index, 'standard')
                )}
              </MapSidebarDropdown>
                {salesPoints?.length <= 0 && <MapSidebarDivider />}
              </>
            )}
            {salesPoints?.length > 0 && (
              <>
              <MapSidebarDivider />
              <MapSidebarDropdown open={thirdDropdownOpen}>
                <MapSidebarDropdownHeader onClick={() => setThirdDropdownOpen(!thirdDropdownOpen)}>
                  {salesPointTitle}
                </MapSidebarDropdownHeader>
                <MapSidebarDropdownButton>
                  {thirdDropdownOpen ? "-" : "+"}
                </MapSidebarDropdownButton>
                {/* <MapSidebarTitle>{salesPointTitle}</MapSidebarTitle> */}
                {salesPoints.map(({ properties, geometry }, index) =>
                  shopJsxStructure(properties, geometry, index, 'standard')
                )}
              </MapSidebarDropdown>
              <MapSidebarDivider />
              </>
            )}
          </MapSidebarBody>
        </OverflowScroll>
      </MapSidebar>
    </Wrapper>
  );
};

export default FindUs;

const Wrapper = styled.div`
  position: relative;
  height: auto;
  width: 100vw;
  padding-top: ${mobileVW(70)};
  display: flex;
  flex-direction: column;

  ${desktopBreakpoint} {
    padding-top: 0;
    height: 100vh;
  }
`;

const MapMarker = styled.div`
  position: relative;
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 40vh;

  ${desktopBreakpoint} {
    height: 100%;
    padding-left: 85px;
  }
`;

const MapSidebar = styled.div`
  position: relative;
  z-index: ${zIndex.surface};
  height: auto;
  background-color: white;
  top: auto;
  left: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${desktopBreakpoint} {
    position: absolute;
    top: ${desktopVW(110)};
    left: 0;
    width: ${desktopVW(550)};
    height: calc(100% - ${desktopVW(110)});
  }
`;

const MapSidebarDropdown = styled.div`
  height: ${({open}) => !open ? "80px" : "unset" };
  overflow: hidden;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
`

const MapSidebarDropdownButton = styled.div`
  position: absolute;
  right: 0;
  top: 31px;
  right: 31px;
  pointer-events: none;
`

const MapSidebarDropdownHeader = styled.div`
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  text-transform: capitalize;
  font-weight: 200;
  font-size: ${mobileVW(20)};
  color: ${colors.black};
  ${desktopBreakpoint} {
    height: 80px;
    font-size: ${desktopVW(20)};
  }
`

const MapSidebarHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${mobileVW(50)} ${mobileVW(50)} ${mobileVW(30)};
  height: ${mobileVW(215)};

  button {
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${desktopVW(14)};
    letter-spacing: -0.05em;
    font-size: ${mobileVW(20)};
    font-weight: 400;
    text-transform: capitalize;
    ${desktopBreakpoint} {
      font-size: ${desktopVW(15)};
    }
  }
  flex-shrink: 0;
  ${desktopBreakpoint} {
    height: ${desktopVW(185)};
    padding: ${desktopVW(55)} ${desktopVW(67)} ${desktopVW(10)};
    //padding: ${desktopVW(55)} 0 ${desktopVW(10)};
  }
`;

const MapCountrySelectTitle = styled.h2`
  align-self: flex-start;
  font-weight: 200;
  font-family: "messinaMono";
  color: ${colors.subtleGrey};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(18)};
  }
`

const MapCountrySelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

const MapCountrySelect = styled.select`
  border: none;
  outline: none;
  background: none;
  appearance: none;
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  font-size: ${mobileVW(32)};
  letter-spacing: -0.05em;
  font-weight: 400;
  text-transform: capitalize;
  border: 1px solid ${colors.subtleGrey};
  border-radius: 0;
  width: 100%;
  color: ${colors.black};
  padding: ${mobileVW(20)} ${mobileVW(10)};
  ${desktopBreakpoint} {
    padding: ${desktopVW(17)} ${desktopVW(13)};
    font-size: ${desktopVW(25)};
  }
`;

const MapCountrySelectArrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
  right: 25px;
  z-index: 10;
  border: solid ${colors.subtleGrey};
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
`

const MapSidebarBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
`;

const MapSidebarTitle = styled.h3`
  font-family: 'messinaMono', 'Courier New', Courier, monospace;
  text-transform: capitalize;
  margin: ${mobileVW(30)} 0 0 0;
  font-weight: 200;
  color: ${colors.black};
  ${desktopBreakpoint} {
    font-size: ${desktopVW(20)};
    margin: 30px 0 30px 0;
  }
`;

const MapSidebarDivider = styled.div`
  width: 100%;
  background: ${colors.subtleGrey};
  height: 1px;
  ${desktopBreakpoint} {
    height: 1px;
  }
`;

const OverflowScroll = styled.div`
  width: 75%;
  margin: 10px auto 20px auto;

  overflow: auto;
  height: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    background: transparent;
  }

  ${desktopBreakpoint} {
    margin: 40px auto 20px auto;
    width: 75%;
    overflow-x: auto;
    overflow-y: scroll;
  }
`;

const StoreThumbnail = styled.div<{ isLast: boolean, isFirst: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  //border-bottom: ${({ isLast }) => (isLast ? 'none' : `${mobileVW(2)} solid ${colors.cappuccino}`)};
  margin: ${mobileVW(20)} 0 0;
  ${({isFirst}) => isFirst && `
    margin-top: 0;
  `}
  padding-bottom: ${mobileVW(30)};

  ${desktopBreakpoint} {
/*     border-bottom: ${({ isLast }) =>
      isLast ? 'none' : `${desktopVW(1)} solid ${colors.cappuccino}`}; */
    margin: ${desktopVW(15)} 0 0;
    ${({isFirst}) => isFirst && `
      margin-top: 0;
    `}
    padding-bottom: ${desktopVW(15)};
  }
`;

const StyledImg = styled(Img)`
  min-width: ${mobileVW(110)};
  height: ${mobileVW(110)};

  ${desktopBreakpoint} {
    min-width: ${desktopVW(135)};
    height: ${desktopVW(135)};
  }
`;

const StoreInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 0 0 ${mobileVW(20)};

  .storeInfoTitle {
    color: ${colors.black};
    ${desktopBreakpoint} {
      font-size: ${desktopVW(17)};
    }
  }

  .storeInfoParagraph {
    color: ${colors.subtleGrey};
  }

  a {
    text-decoration: none;
  }

  p,
  a {
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${desktopVW(14)};
    letter-spacing: -0.05em;
    font-size: ${mobileVW(16)};

    ${desktopBreakpoint} {
      font-size: ${desktopVW(14)};
    }
  }

  ${desktopBreakpoint} {
    padding: 0 0 0 ${desktopVW(26)};
    height: ${desktopVW(115)};
    margin: auto 0;
  }
`;

const InfoBox = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: auto;
  min-width: ${mobileVW(230)};
  min-height: ${mobileVW(150)};
  padding: ${mobileVW(20)};
  bottom: ${mobileVW(30)};

  ${({ isActive }) => (isActive ? 'opacity: 1;' : 'opacity: 0;')}

  .storeInfoTitle {
    color: ${colors.black};
    margin-bottom: ${mobileVW(15)};
    font-size: ${mobileVW(30)};
    padding-right: ${mobileVW(10)};
    ${desktopBreakpoint} {
      margin-bottom: ${desktopVW(10)};
      padding-right: ${desktopVW(10)};
      font-size: ${desktopVW(30)};
    }
  }

  .storeInfoParagraph {
    color: ${colors.black};
  }

  a {
    text-decoration: underline;
    margin-top: ${mobileVW(15)};
    ${desktopBreakpoint} {
      margin-top: ${desktopVW(10)};
    }
  }

  p,
  a {
    font-family: 'messinaMono', 'Courier New', Courier, monospace;
    font-size: ${mobileVW(14)};
    letter-spacing: -0.05em;

    ${desktopBreakpoint} {
      font-size: ${desktopVW(12)};
    }
  }

  .thumbnailCloseInfo {
    position: absolute;
    color: ${colors.black};
    top: ${mobileVW(30)};
    right: ${mobileVW(15)};
    font-size: ${mobileVW(17)};
    ${desktopBreakpoint} {
      top: ${desktopVW(36)};
      right: ${desktopVW(30)};
      font-size: ${desktopVW(20)};
    }
  }

  ${desktopBreakpoint} {
    min-width: ${desktopVW(310)};
    min-height: ${desktopVW(170)};
    padding: ${desktopVW(30)};
    bottom: ${desktopVW(25)};
  }
`;

export const FindUsPageQuery = graphql`
  query FindUsPage($id: String!) {
    contentfulPageFindUs(id: { eq: $id }) {
      storeLocatorText
      premiumDealersTitle
      premiumDealersMarkerColor
      standardDealersTitle
      standardDealersMarkerColor
      salesPointTitle
      salesPointMarkerColor
      stores {
        title
        storeType
        addressFirstLine
        addressSecondLine
        country
        website
        location {
          lat
          lon
        }
        thumbnailImage {
          fluid(maxWidth: 180, quality: 50) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;
